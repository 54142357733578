import React from 'react'
import Layout from '../../components/layout'
import ServiceIntroduction from '../../components/serviceIntroduction'
import taloIcon from "../../icons/houseIcon.svg"
import Seo from "../../components/Seo";
import { graphql, useStaticQuery } from "gatsby";

const Uudisrakentaminen = () => {

const uudisrakentaminenSeoPic = useStaticQuery(graphql`
    query uudisrakentaminenSeoPic {
      image: file(relativePath: { eq: "square/Ylitalo-21.jpg" }) {
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

    return (
        <Layout>
    <Seo
        title="Uudisrakentaminen | Ylitalon"
        description="Rakennamme omakotitalot, kesämökit sekä autotallit perustuksista viimeiseen listaan. Teemme asiakkaan näköisiä taloja jokapäiväiseen käyttöön Lahden alueella."
        keywords={["uudisrakentaminen", "miniomakotitalot", "kesämökki", "autotalli", "autokatos", "Lahti", "Lahden talousalue", "Porvoo", "Orimattila", "Hollola"]}
        url="https://ylitalon.netlify.app/uudisrakentaminen"
        image={"https://ylitalon.netlify.app" + uudisrakentaminenSeoPic.image.publicURL}
      />
            <ServiceIntroduction
                to={"/palvelut"}
                src={"square/Ylitalo-29.jpg"}
                title={"Uudisrakentaminen"}
                titleSize={[6, 10]}
                smallTitle1={"Miniomakotitalot"}
                mediumTitle={"Ihastuttavia omakotitaloja"}
                infoText1={"Rakennamme omakotitalot, kesämökit, autotallit ja -katokset sekä hallit perustuksista viimeiseen listaan. Toteutamme myös pihatyöt. Tarvittaessa saat kauttamme suunnittelupalvelun ja haemme puolestasi vaadittavat rakennus- tai toimenpideluvat. Keskitymme rakentamaan toimivia ja asiakkaan näköisiä taloja jokapäiväiseen käyttöön."}
                listTitle={"_"}
                icon={taloIcon}
                infoText2={"Askolaan on syksyllä 2021 valmistunut kunnan ensimmäiset miniomakotitalot. Pinta-alaltaan XXm2 omakotitalot ovat loistava, edullinen ja energiatehokas vaihtoehto yksin tai kaksin asuvalle kerrostalon sijaan. Lue lisää Askolan miniomakotitaloista "}
                linkTo={["refrensseistämme.", "/referenssit"]}
                list={"_"}
            />
        </Layout>
    )
}

export default Uudisrakentaminen